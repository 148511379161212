(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("emailAttachmentsController", emailAttachmentsController);

	emailAttachmentsController.$inject = ["$scope", "$mdDialog", "$http", "errorHandling", "message", "authStorage", "localeInfoService","userDataService","$rootScope"];

	function emailAttachmentsController($scope, $mdDialog, $http, errorHandling, message, authStorage, localeInfoService,userDataService, $rootScope) {
		$scope.controller = this;
		$scope.attachments = message.attachments || [];
		$scope.allAttachmentFile = null;
		$scope.showOptions = false;

		activate();
		//////////////////////////

		function activate() {
			$rootScope.spinner.show();
			userDataService.init()

				.then(function () {
					$scope.features = userDataService.user.settings.features;
					$scope.enableOnlyOffice = $scope.features.enableOnlyOffice;
					$scope.enablewopi = $scope.features.enableWOPI;
					$scope.wopiurl = $scope.features.wopiURL;
					$rootScope.spinner.hide();
				});
		}
		
		
		for (let i = 0; i < $scope.attachments.length; i++) {
			const item = $scope.attachments[i];
			if (item.allAttachments) {
				$scope.allAttachmentFile = item;
				break;
			}
		}

		$scope.close = function () {
			$mdDialog.cancel();
		};
		
		
		

		// WOPI Edit Function
		$scope.wopiEdit = async function (item) {
			try {
				const wopiToken = await getWopiToken();
				if (!wopiToken) {
					errorHandling.report("Error obtaining WOPI token.");
					return;
				}

				const wopiSrcUrl = `${window.location.origin}${item.link}?access_token=${wopiToken}`;
				const wopiClientUrl = $scope.wopiurl + "/browser/77a0aab/cool.html";
				if (!wopiClientUrl) {
					errorHandling.report("Error obtaining WOPI client URL.");
					return;
				}

				const encodedWopiSrc = encodeURIComponent(wopiSrcUrl);
				const urlToOpen = `${wopiClientUrl}?WOPISrc=${encodedWopiSrc}`;

				window.open(urlToOpen, '_blank');
			} catch (error) {
				console.error("Error in wopiEdit:", error);
				errorHandling.report("Error opening document.");
			}
		};

		// OnlyOffice Edit Function
		$scope.onlyOfficeEdit = async function (item) {
			try {
				const onlyOfficeUrl = await getOnlyOfficeURL();
				if (!onlyOfficeUrl) {
					errorHandling.report("Error obtaining OnlyOffice URL.");
					return;
				}

				const downloadLink = `${window.location.origin}/api/v1/filestorage/${item.id}/download?token=${authStorage.getToken()}`;
				const docType = getDocTypeByExtension(item.type);
				const key = generateRandom12DigitNumber();

				const payload = {
					filetype: item.type,
					key: key,
					title: item.filename,
					url: downloadLink,
					doctype: docType,
					callbackUrl: `${window.location.origin}/api/v1/settings/onlyoffice-callback/${item.id}`,
					lang: localeInfoService.language,
					mode: "view",
					name: authStorage.getUserEmail(),
				};

				const jwtToken = await getOnlyOfficeJwtToken(payload);
				if (!jwtToken) {
					errorHandling.report("Error obtaining OnlyOffice JWT token.");
					return;
				}

				initOnlyOfficeEditor(onlyOfficeUrl, jwtToken, payload);
			} catch (error) {
				console.error("Error in onlyOfficeEdit:", error);
				errorHandling.report("Error opening document.");
			}
		};

		// Helper Functions for WOPI
		async function getWopiToken() {
			try {
				const response = await $http.post("~/wopi/GetWOPIAccessToken");
				return response.data?.token || null;
			} catch (error) {
				console.error("Error obtaining WOPI access token:", error);
				return null;
			}
		}
		

		// Helper Functions for OnlyOffice
		function getOnlyOfficeURL() {
			return $http.get("~/api/v1/settings/onlyoffice-url")
				.then(response => {
					if (response.data && response.data.message) {
						return response.data.message;
					} else {
						throw new Error('Unexpected response structure: ' + JSON.stringify(response.data));
					}
				})
				.catch(error => {
					console.error("Error obtaining OnlyOffice URL:", error);
					throw error;
				});
		}

		async function getOnlyOfficeJwtToken(payload) {
			try {
				const response = await $http.post("~/api/v1/settings/onlyoffice-jwt", payload);
				return response.data?.token || null;
			} catch (error) {
				console.error("Error obtaining OnlyOffice JWT token:", error);
				return null;
			}
		}

		function initOnlyOfficeEditor(onlyOfficeUrl, jwtToken, payload) {
			const editorConfig = {
				document: {
					fileType: payload.filetype,
					key: payload.key,
					title: payload.title,
					url: payload.url,
					permissions: { edit: false }, // View mode
				},
				editorConfig: {
					mode: payload.mode,
					lang: payload.lang,
					user: {
						id: authStorage.getUserEmail(),
						name: authStorage.getUserEmail(),
					},
					callbackUrl: payload.callbackUrl,
				},
				token: jwtToken,
			};

			const editorUrl = `${onlyOfficeUrl}?doc=${encodeURIComponent(JSON.stringify(editorConfig))}`;
			window.open(editorUrl, '_blank');
		}

		// Utility Functions
		function generateRandom12DigitNumber() {
			return String(Math.floor(100000000000 + Math.random() * 900000000000));
		}

		function getDocTypeByExtension(extension) {
			const mapping = {
				'docx': 'word',
				'doc': 'word',
				'odt': 'word',
				'xlsx': 'cell',
				'xls': 'cell',
				'ods': 'cell',
				'pptx': 'slide',
				'ppt': 'slide',
				'odp': 'slide',
			};
			return mapping[extension.toLowerCase()] || 'word';
		}
	}
})();